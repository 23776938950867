<template>
  <v-container
    id="clients"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-col v-if="filterHasDatepicker[statusFilter]">
      <div style="display: flex; justify-content: flex-end; padding-bottom: 10px;">
        <div style="width: 100%; max-width: 200px;">
          <v-menu
            v-model="filtersDatepickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            style="display: block;"
            content-class="menu-overlay-chat"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-if="filterHasDatepicker[statusFilter].datepicker !== 'single'"
                v-model="filtersDatepickerText"
                :label="str['interval_dates']"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
              />
              <v-text-field
                v-if="filterHasDatepicker[statusFilter].datepicker === 'single'"
                v-model="filtersSingleDatepickerText"
                :label="str['interval_dates']"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
              />
            </template>
            <v-date-picker
              v-if="filterHasDatepicker[statusFilter].datepicker !== 'single'"
              v-model="filtersDatepickerDates"
              range
              style="margin: 0;"
              @change="applyRefreshListFilter"
              :max="filterHasDatepicker[statusFilter].notAllowFutureDates ? currentDate: null"
              :locale="datepickerLanguage"
            />
            <v-date-picker
              v-if="filterHasDatepicker[statusFilter].datepicker === 'single'"
              v-model="filtersSingleDatepickerDate"
              style="margin: 0;"
              @change="applyRefreshListFilter"
              :max="filterHasDatepicker[statusFilter].notAllowFutureDates ? currentDate: null"
              :locale="datepickerLanguage"
            />
          </v-menu>
        </div>
      </div>
    </v-col>
    <v-data-table
      ref="clientsTableRef"
      :headers="headers"
      :items="clients"
      :items-per-page="tableItemsPerPage"
      :page="currentPage"
      :server-items-length="filterHasPagination[statusFilter] ? tableTotalItems : undefined"
      :search="search"
      :custom-filter="customTableSearch"
      :loading="filterHasPagination[statusFilter] ? isTableLoading : undefined"
      :loading-text="str['processing']"
      :no-data-text="str['clients_empty']"
      :no-results-text="str['clients_empty']"
      class="elevation-1"
      :class="{'table-no-items-per-row table-no-counter': !hasPermission('clients_table_pages'), 'table-no-items-per-row': filterHasPagination[statusFilter], 'table-disabled': filterHasPagination[statusFilter] && isTableLoading}"
      @click:row="openClient"
      @update:page="changePage"
      @update:items-per-page="updateTableItemsPerPage"
      @update:sort-by="onTableAscSort"
      @update:sort-desc="onTableDescSort"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="searchTemp"
            @input="onSearch"
            @keyup.enter="applyTableSearch"
            @click:append="applyTableSearch"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <div class="mb-2">
            <v-btn
              v-if="hasPermission('client_export_email')"
              color="secondary"
              dark
              class="mb-0"
              @click="exportClients()"
              style="margin-right: 10px;"
            >
              {{ str['export'] }}
            </v-btn>
            <v-btn
              v-if="!hasPermission('client_export_email') && filterHasExportCsv[statusFilter]"
              color="secondary"
              dark
              class="mb-0"
              @click="exportClients(true)"
              style="margin-right: 10px;"
            >
              {{ str['export'] }}
            </v-btn>
            <v-btn
              v-if="showExportPayments && hasPermission('client_export_email')"
              color="secondary"
              dark
              class="mb-0"
              @click="openExportPayments()"
              style="margin-right: 10px;"
            >
              {{ str['export_payments'] }}
            </v-btn>
            <v-btn
              v-if="showSendChatMessages"
              color="info"
              dark
              class="mb-0"
              @click="openSendMessage()"
              style="margin-right: 10px;"
            >
              {{ str['send_message'] }}
            </v-btn>
            <v-btn
              v-if="showBlockClients"
              color="error"
              dark
              class="mb-0"
              @click="openBlockClients()"
              style="margin-right: 10px;"
            >
              {{ str['block_clients'] }}
            </v-btn>
            <v-btn
              v-if="showSendNotifications"
              color="info"
              dark
              class="mb-0"
              @click="openSendNotifications()"
              style="margin-right: 10px;"
            >
              {{ str['send_notification'] }}
            </v-btn>
            <v-btn
              color="success"
              dark
              class="mb-0"
              @click="openNewClient()"
            >
              {{ str['add'] }}
            </v-btn>
          </div>
          <v-dialog
            v-model="dialogClient"
            persistent
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['new_client'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in newClientData"
                      :key="item.id"
                      cols="12"
                    >
                      <v-text-field
                        v-if="item.type === 'input'"
                        v-model="item.value"
                        :label="item.title"
                      />

                      <v-autocomplete
                        v-if="item.type === 'select'"
                        :label="item.title"
                        item-text="label"
                        item-value="value"
                        v-model="item.value"
                        :items="item.items"
                        :no-data-text="str['no_data']"
                      />
                      <v-menu
                        v-if="item.type === 'datepicker'"
                        v-model="datepickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.value"
                            :label="item.title"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="item.value"
                          style="margin: 0;"
                          @change="datepickerMenu = false"
                          :locale="datepickerLanguage"
                        />
                      </v-menu>
                      <v-menu
                        v-if="item.type === 'datepickerPayday' && item.visible"
                        v-model="datepickerPaydayMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.value"
                            :label="item.title"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="item.value"
                          style="margin: 0;"
                          @change="datepickerPaydayMenu = false"
                          :locale="datepickerLanguage"
                        />
                      </v-menu>
                      <v-menu
                        v-if="item.type === 'datepickerFeedbackDay' && item.visible"
                        v-model="datepickerFeedbackDayMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.value"
                            :label="item.title"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="item.value"
                          style="margin: 0;"
                          @change="datepickerFeedbackDayMenu = false"
                          :locale="datepickerLanguage"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="cancelNewClient"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="saveNewClient"
                >
                  {{ str['add'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogSendMessage"
            persistent
            max-width="900px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                 {{ str['send_message_filtered_clients'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        v-model="message.text"
                        :label="str['message']"
                        rows="5"
                        hide-details
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      style="font-size: 12px; padding-bottom: 0;"
                    >
                      <div>
                        <b>{{ str['selected_clients'] }}:</b> {{ sendMessageSelectedClients.length }}
                      </div>
                      <div v-if="sendMessageSelectedClients.length">
                        {{ sendMessageSelectedClients.join(', ') }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pb-0"
                    >
                      <h5>
                        {{ str['send_message_all_info'] }}
                      </h5>
                    </v-col>
                    <v-col
                      cols="12"
                      class="table-no-items-per-row position-relative"
                      :class="{'pointer-none': sendMessageTableLoading}"
                    >
                      <div
                        v-if="sendMessageTableLoading"
                        class="custom-loading"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <v-data-table
                        ref="sendMessageTableRef"
                        :headers="sendMessageHeaders"
                        :items="message.clients"
                        :search="sendMessageSearch"
                        :items-per-page="sendMessageItemsPerPage"
                        :no-data-text="str['clients_empty']"
                        :no-results-text="str['clients_empty']"
                        @click:row="selectSendMessageClient"
                        @pagination="refreshSendMessageClients"
                      >
                        <template v-slot:top>
                          <v-toolbar
                            flat
                            class="table-toolbar-padding"
                          >
                            <div
                              @click="selectAllSendMessageClients()"
                            >
                              <v-checkbox
                                v-model="message.selectedAll"
                                color="secondary"
                                style="pointer-events: none;"
                                hide-details
                              />
                            </div>
                            <v-spacer />
                            <v-text-field
                              v-model="sendMessageSearch"
                              append-icon="mdi-magnify"
                              :label="str['search']"
                              single-line
                              hide-details
                            />
                            <div style="margin-left: 10px; width: 25px;">
                              <v-icon
                                v-if="!sendMessageTableLoading"
                                color="blue"
                                class="cursor-hover"
                                style="font-size: 25px;"
                                @click.stop="refreshSendMessageClients()"
                              >
                                mdi-refresh
                              </v-icon>
                              <div v-if="sendMessageTableLoading">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="icon-loading"
                                ></v-progress-circular>
                              </div>
                            </div>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.checkbox="{ item }">
                          <v-checkbox
                            v-model="item.selected"
                            color="secondary"
                            style="pointer-events: none;"
                          />
                        </template>
                        <template v-slot:item.has_food_plan="{ item }">
                          <v-icon
                            v-if="item.has_food_plan"
                            color="success"
                          >
                            mdi-check-circle
                          </v-icon>
                          <v-icon
                            v-if="!item.has_food_plan"
                            color="error"
                          >
                            mdi-close-circle
                          </v-icon>
                        </template>
                        <template v-slot:item.has_training_plan="{ item }">
                          <v-icon
                            v-if="item.has_training_plan"
                            color="success"
                          >
                            mdi-check-circle
                          </v-icon>
                          <v-icon
                            v-if="!item.has_training_plan"
                            color="error"
                          >
                            mdi-close-circle
                          </v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            :color="item.has_unread_messages ? 'error' : 'blue'"
                            class="cursor-hover"
                            @click.stop="openClientChat(item)"
                          >
                            mdi-chat
                          </v-icon>
                        </template>
                        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeSendMessage"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="sendMessage"
                >
                  {{ str['send'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-if="showSendNotifications"
            v-model="dialogSendNotifications"
            persistent
            max-width="750px"
          >
            <v-card v-if="notificationsToSend">
              <v-card-title>
                <span class="headline">
                 {{ str['notification'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="notificationsToSend.title"
                        :label="str['notification_title']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="notificationsToSend.body"
                        :label="str['notification_message']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="notificationsToSend.clients"
                        :items="clients"
                        item-text="name"
                        item-value="id"
                        :label="str['select_clients']"
                        multiple
                        :hint="str['select_clients_to_send_notification']"
                        :no-data-text="str['no_data']"
                        persistent-hint
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeSendNotifications()"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="warning"
                  @click="sendNotifications(true)"
                  :disabled="!notificationsToSend.title || !notificationsToSend.body"
                >
                  {{ str['send_to_all_clients'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="sendNotifications()"
                  :disabled="!notificationsToSend.title || !notificationsToSend.body || !notificationsToSend.clients.length"
                >
                  {{ str['send'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-if="showBlockClients"
            v-model="dialogBlockClients"
            persistent
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                 {{ str['select_clients_to_block'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-autocomplete
                        v-model="blockClientsList"
                        :label="str['select_clients']"
                        item-text="name"
                        item-value="id"
                        :items="clients"
                        :multiple="true"
                        :no-data-text="str['no_data']"
                        :search-input.sync="blockClientsSearchInput"
                        @change="blockClientsSearchInput=''"
                      >
                        <div
                          slot="prepend-item"
                          ripple
                          @click.stop="blockClientsList.length !== clients.length ? blockClientsList = clients.map(it => it.id) : blockClientsList = []"
                          class="checkbox-element cursor-hover"
                        >
                          <v-icon v-if="blockClientsList.length !== clients.length">
                            mdi-checkbox-blank-outline
                          </v-icon>
                          <v-icon v-if="blockClientsList.length === clients.length">
                            mdi-checkbox-marked
                          </v-icon>
                          <div>
                            {{ str['select_all'] }}
                          </div>
                        </div>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeBlockClients()"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="error"
                  @click="confirmBlockClients()"
                  :disabled="!blockClientsList.length"
                >
                  {{ str['block'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogExportPayments"
            persistent
            max-width="400px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                 {{ str['export_payments'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <div style="min-width: 200px; width: 100%; max-width: 200px;">
                        <v-menu
                          v-model="exportPaymentsDatepickerMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                          style="display: block;"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="exportPaymentsDatepickerDatesText"
                              :label="str['interval_dates']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            />
                          </template>
                          <v-date-picker
                            v-model="exportPaymentsDatepickerDates"
                            range
                            style="margin: 0;"
                            :locale="datepickerLanguage"
                          />
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeExportPayments"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="exportPayments"
                >
                  {{ str['confirm'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar
          flat
        >
          <div style="display: flex; justify-content: space-between; align-items: flex-end; width: 100%; height: 40px;">
            <div class="row-full-width row-flex-align">
              <v-autocomplete
                v-model="statusFilter"
                :label="str['list_verb']"
                item-text="label"
                item-value="value"
                :items="statusFilterItems"
                @change="refreshClientsList()"
                :no-data-text="str['no_data']"
                hide-details
                style="max-width: 300px"
              />
              <v-icon
                v-if="filterHasInfo[statusFilter]"
                color="secondary"
                dark
                class="cursor-hover ml-1"
                @click.stop="showFilterInfo(filterHasInfo[statusFilter])"
              >
                mdi-information
              </v-icon>
              <v-checkbox
                v-if="isEmployee && filterEmployeeCheckbox"
                v-model="filterEmployee"
                :label="str['view_only_my_clients']"
                class="form-field-checkbox ml-4"
                @change="changeFilterEmployeeCheckbox"
                hide-details
              />
            </div>
            <div v-if="filterHasPaymentsPacks[statusFilter]">
              <v-autocomplete
                v-model="paymentsPacksFilter"
                :label="str['payment_pack']"
                item-text="name"
                item-value="id"
                :items="paymentsPacks"
                @change="resetClientsList()"
                :no-data-text="str['no_data']"
                clearable
                hide-details
                style="max-width: 300px"
              />
            </div>
            <div
              v-if="filterHasInputs[statusFilter]"
              style="display: flex; align-items: center; padding-left: 10px;"
            >
              <v-text-field
                :label="filterHasInputs[statusFilter].minText ? (str[filterHasInputs[statusFilter].minText] ? str[filterHasInputs[statusFilter].minText] : filterHasInputs[statusFilter].minText) : str['min']"
                v-model="filterHasInputs[statusFilter].min"
                style="padding: 0; margin-right: 5px;"
                class="input-small"
                outlined
                hide-details
              />
              <v-text-field
                v-if="filterHasInputs[statusFilter].max !== false"
                :label="filterHasInputs[statusFilter].maxText ? (str[filterHasInputs[statusFilter].maxText] ? str[filterHasInputs[statusFilter].maxText] : filterHasInputs[statusFilter].maxText) : str['max']"
                v-model="filterHasInputs[statusFilter].max"
                style="padding: 0;"
                class="input-small"
                outlined
                hide-details
              />
              <v-btn
                color="purple"
                class="mb-0"
                style="margin-left: 15px; height: 40px;"
                dark
                @click="applyRefreshListFilter()"
                :disabled="intervalFilterDisabled"
              >
                {{ str['apply'] }}
              </v-btn>
            </div>
          </div>
        </v-toolbar>
        <div
          v-if="statusFilterSubFilters()"
          style="padding: 10px 15px 25px 31px; display: flex; align-items: flex-end;"
        >
          <div class="row-flex-align">
            <div
              v-for="subFilter in statusFilterSubFilters()"
              :key="subFilter.id"
              style="max-width: 240px; padding-right: 20px;"
            >
              <div>
                {{ subFilter.title }}
              </div>
              <div>
                <v-text-field
                  type="number"
                  v-model="subFilter.value"
                  style="padding: 0;"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div>
            <v-btn
              color="blue"
              dark
              @click="refreshClientsList()"
            >
              {{ str['filter'] }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:item.id="{ item }">
        <div
          v-if="status2Enabled"
          :style="{display: 'flex'}"
        >
          <v-icon
            v-if="item.status2"
            small
            class="mr-1"
            :color="item.status2"
            @click.stop="openClientStatus2Warning(item)"
          >
            mdi-alert-circle
          </v-icon>
          <v-icon
            v-if="hasChat"
            small
            class="mr-1"
            color="secondary"
            @click.stop="openClientChat(item)"
          >
            mdi-chat
          </v-icon>
          <v-icon
            v-if="showWhatsapp"
            small
            class="mr-1"
            :color="showWhatsappApi ? 'gray' : 'success'"
            @click.stop="openClientWhatsapp(item)"
          >
            mdi-whatsapp
          </v-icon>
          <v-icon
            v-if="showWhatsappApi"
            small
            class="mr-1"
            color="success"
            @click.stop="openClientWhatsappApi(item)"
          >
            mdi-whatsapp
          </v-icon>
          {{ item.id }}
        </div>
        <div v-if="!status2Enabled">
          <v-icon
            v-if="hasChat"
            small
            class="mr-1"
            color="secondary"
            @click.stop="openClientChat(item)"
          >
            mdi-chat
          </v-icon>
          {{ item.id }}
        </div>
      </template>
      <template v-slot:item.photo="{ item }">
        <div
          class="p-2 text-center"
          style="display: flex; justify-content: center; padding: 10px 0;"
        >
          <v-img
            contain
            :src="item.photo"
            :alt="item.name"
            width="60px"
            height="60px"
          />
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <span
          v-if="clientsStatusDict[item.status]"
          :class="[clientsStatusDict[item.status] && clientsStatusDict[item.status].type ? (clientsStatusDict[item.status].type + '--text') : '']"
        >
          {{ clientsStatusDict[item.status].label }}
        </span>
        <span
          v-if="!clientsStatusDict[item.status]"
        >
          {{ '-' }}
        </span>
      </template>
      <template v-slot:item.tags="{ item }">
        <div v-if="item.tags && item.tags.length">
          <div
            v-for="tag in item.tags"
            :key="tag"
            :style="{color: tagsDict && tagsDict[tag] && tagsDict[tag].color}"
            class="text-small"
          >
            {{ tag }}
          </div>
        </div>
        <div v-if="!item.tags || (item.tags && !item.tags.length)">
          -
        </div>
      </template>
      <template v-slot:item.payday="{ item }">
        {{ item.payday }}
        <v-icon
          v-if="showPaymPri && item.paym_pri && item.paym_pri.indexOf('price_') === -1"
          color="error"
          dark
          class="cursor-hover ml-1"
          @click.stop="showInformation(item.paym_pri)"
        >
          mdi-information
        </v-icon>
        <v-icon
          v-if="(showPaymError && item.paym_error) || (paymentType && paymentType.client_paym_error && paymentType.client_paym_error.values.indexOf(item[paymentType.client_paym_error.field]) > -1)"
          color="error"
          dark
          class="cursor-hover ml-1"
          @click.stop="showInformation(item.paym_error)"
        >
          mdi-information
        </v-icon>
      </template>
      <template v-slot:item.employee1="{ item }">
        {{ item.employee1 && employeesDict[item.employee1] ? employeesDict[item.employee1].name : '' }}
        {{ item.employee2 && employeesDict[item.employee2] ? ((item.employee1 ? ' | ' : '' ) + employeesDict[item.employee2].name) : '' }}
        {{ (!item.employee1 || !employeesDict[item.employee1]) && (!item.employee2 || !employeesDict[item.employee2]) ? '-' : '' }}
      </template>
      <template v-slot:item.purchase_date="{ item }">
        {{ item.purchase_date ? item.purchase_date : '-' }}
      </template>
      <template v-slot:item.register_date="{ item }">
        {{ item.register_date ? item.register_date : '-' }}
      </template>
      <template v-slot:item.feedback_day="{ item }">
        {{ getClientFeedbackDay(item.feedback_day) }}
      </template>
      <template v-slot:item.last_login_dt="{ item }">
        {{ item.last_login_dt ? item.last_login_dt : '-' }}
      </template>
      <template v-slot:item.chat_last_msg="{ item }">
        <div>
          {{ item.chat_last_msg ? item.chat_last_msg : '-' }}
        </div>
        <div
          v-if="item.chat_last_msg"
          style="margin-top: -3px;"
        >
          {{ str['sent_to'] }} <b>{{
            moment.duration(moment().diff(moment(item.chat_last_msg))).asHours() > 48 ?
            (Math.floor(moment.duration(moment().diff(moment(item.chat_last_msg))).asDays()) + ' dias') :
            (Math.floor(moment.duration(moment().diff(moment(item.chat_last_msg))).asHours()) + ' horas')
          }}</b>
        </div>
      </template>
      <template v-slot:item.mobile_phone="{ item }">
        <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 120px;">
          {{ item.mobile_phone ? item.mobile_phone : '-' }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div style="display: flex; justify-content: center;">
          <v-icon
            v-if="item.status !== statusMap.active"
            small
            class="mr-2"
            color="success"
            @click.stop="activeClient(item)"
          >
            mdi-account-check
          </v-icon>
          <v-icon
            v-if="item.status !== statusMap.suspended"
            small
            class="mr-2"
            color="warning"
            @click.stop="suspendClient(item)"
          >
            mdi-lock
          </v-icon>
          <v-icon
            v-if="showUpdateToNewRegister"
            small
            class="mr-2"
            color="info"
            @click.stop="updateToNewRegisterClient(item)"
          >
            mdi-account-reactivate
          </v-icon>
          <v-icon
            v-if="item.status !== statusMap.blocked"
            small
            class="mr-0"
            color="error"
            @click.stop="blockClient(item)"
          >
            mdi-block-helper
          </v-icon>
        </div>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog
      v-if="showWhatsappApi"
      v-model="dialogSelectClientWhatsappApi"
      persistent
      max-width="300px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['whatsapp'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                v-for="url in showWhatsappApi.urls"
                :key="url.id"
                cols="12"
              >
                <v-btn
                  :color="url.color"
                  @click="selectClientWhatsappApi(url)"
                  class="row-full-width"
                >
                  {{ str[url.name] ? str[url.name] : url.name }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="default"
                  @click="dialogSelectClientWhatsappApi = false"
                  class="row-full-width"
                >
                  {{ str['close'] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showWhatsappApi"
      v-model="dialogClientWhatsappApi"
      persistent
      max-width="300px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['whatsapp'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-btn
                  color="success"
                  @click="openClientWhatsappApiMessage()"
                  class="row-full-width"
                >
                  {{ str['send_message'] }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="blue"
                  @click="openClientWhatsappApiLink()"
                  class="row-full-width"
                >
                  {{ str['open_link'] }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="default"
                  @click="closeClientWhatsappApi()"
                  class="row-full-width"
                >
                  {{ str['close'] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showWhatsappApi"
      v-model="dialogClientWhatsappApiMessage"
      persistent
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['whatsapp'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                v-for="item in clientWhatsappApiFields"
                :key="item.id"
                cols="12"
              >
                <v-text-field
                  v-if="item.type === 'input'"
                  v-model="item.value"
                  :label="item.title"
                  :disabled="item.disabled ? true : false"
                  hide-details
                />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title"
                  item-text="label"
                  item-value="value"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeClientWhatsappApiMessage()"
          >
            {{ str['close'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="confirmClientWhatsappApiMessage()"
          >
            {{ str['send_message'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNotificationModes"
      persistent
      max-width="450px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['send'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                v-if="notificationsModes.indexOf('push_notification') > -1"
                cols="12"
              >
                <v-btn
                  color="success"
                  @click="sendPushNotification()"
                  class="row-full-width label-text-break"
                >
                  {{ str['push_notification'] }}
                </v-btn>
              </v-col>
              <v-col
                v-if="notificationsModes.indexOf('internal_notification') > -1"
                cols="12"
              >
                <v-btn
                  color="warning"
                  @click="sendInternalNotification()"
                  class="row-full-width label-text-break"
                >
                  {{ str['internal_notification'] }}
                </v-btn>
              </v-col>
              <v-col
                v-if="notificationsModes.indexOf('email') > -1"
                cols="12"
              >
                <v-btn
                  color="blue"
                  @click="sendEmail()"
                  class="row-full-width label-text-break"
                >
                  {{ str['email'] }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="default"
                  @click="closeNotificationModesDialog"
                  class="row-full-width"
                >
                  {{ str['close'] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import ChatApi from '@/services/ChatApi'
  import Utils from '@/services/Utils'
  import axios from 'axios'
  import moment from 'moment'

  export default {
    data() {
      const user = Utils.getUser()
      const isEmployee = user ? Utils.isEmployee(user.type) : false
      const paydayVisible = user && user.configurations && user.configurations.payment && user.configurations.payment.payday
      const feedbackDayVisible = user && user.configurations && user.configurations.feedback && user.configurations.feedback.feedback_day
      const showAssignEmployees = user && !isEmployee && user.configurations && user.configurations.employees && user.configurations.employees.assign_clients
      const showPhotos = false
      const showSendChatMessages = user && user.configurations && user.configurations.clients && user.configurations.clients.show_send_chat_messages ? true : false
      const showSendNotifications = user && user.configurations && user.configurations.clients && user.configurations.clients.send_notifications_button ? true : false
      const showTags = user && user.configurations && user.configurations.clients && user.configurations.clients.show_tags ? true : false
      const showWhatsapp = user && user.configurations && user.configurations.clients && user.configurations.clients.show_whatsapp && Utils.hasPermission('client_open_whatsapp') ? true : false
      const showWhatsappApi = user && user.configurations && user.configurations.clients && user.configurations.clients.show_whatsapp_api && Utils.hasPermission('client_open_whatsapp') ? user.configurations.clients.show_whatsapp_api : false
      const hasChat = user && user.configurations && user.configurations.chat ? true : false
      const hasChatV2 = user && user.configurations && user.configurations.chat && user.configurations.chat.version_2 ? true : false
      const showUpdateToNewRegister = user && user.configurations && user.configurations.clients && user.configurations.clients.show_update_to_new_register && Utils.hasPermission('client_update_to_new_register') ? true : false
      const showPaymPri = window.useHotmart && user && !isEmployee ? true : false
      const showPaymError = user && user.configurations && user.configurations.clients && user.configurations.clients.show_paym_error ? true : false
      const showExportPayments = user && user.configurations && user.configurations.clients && user.configurations.clients.show_export_payments ? true : false
      const status2Enabled = user && user.configurations && user.configurations.clients && user.configurations.clients.show_status2 ? user.configurations.clients.show_status2 : false
      const showBlockClients = user && user.configurations && user.configurations.clients && user.configurations.clients.show_block ? true : false
      const filterEmployee = user && user.configurations && user.configurations.employees && (user.configurations.employees.filter_assign_clients || (user.configurations.employees.filter_assign_clients_checkbox && window.filterEmployeeCheckboxCache !== false)) ? true : false
      const filterEmployeeCheckbox = user && user.configurations && user.configurations.employees && user.configurations.employees.filter_assign_clients_checkbox ? true : false
      const ignoreSearchAccents = user && user.configurations && user.configurations.clients && user.configurations.clients.ignore_search_accents ? true : false
      const exportWithRegisters = user && user.configurations && user.configurations.clients && user.configurations.clients.export_with_registers ? user.configurations.clients.export_with_registers : false
      const notificationsModes = user && user.configurations && user.configurations.notifications && user.configurations.notifications.modes ? user.configurations.notifications.modes : ['push_notification']
      const keepNotificationModesModalOpened = user && user.configurations && user.configurations.notifications && user.configurations.notifications.keep_modal_opened ? true : false
      const paymentType = (Utils.getPaymentTypes() || []).find(p => p.value === window.gatewayType) || null;
      const defaultHeaders = [
        { text: window.strings['photography'], align: 'center', value: 'photo' },
        { text: window.strings['id'], value: 'id', width: showWhatsapp || showWhatsappApi || hasChat ? 150 : 80 },
        { text: window.strings['name'], value: 'name', width: 150 },
        { text: window.strings['email'], value: 'email', width: 200 },
        { text: window.strings['status'], value: 'status', align: 'center', width: 100 },
        { text: window.strings['tags'], value: 'tags', align: 'center', width: 120 },
        { text: window.strings['employee'], value: 'employee1', align: 'center', width: 150 },
        { text: window.strings['feedback_date'], value: 'feedback_day', align: 'center', width: 140 },
        { text: window.strings['payment_date'], value: 'payday', align: 'center', width: 150 },
        { text: window.strings['registration_created_date'], value: 'purchase_date', align: 'center', sort: this.sortDateField, width: 165 },
        { text: window.strings['registration_validated_date'], value: 'register_date', align: 'center', sort: this.sortDateField, width: 165 },
        { text: window.strings['last_login_date'], value: 'last_login_dt', align: 'center', sort: this.sortDateField, width: 150 },
        { text: window.strings['last_message_date'], value: 'chat_last_msg', align: 'center', sort: this.sortDateField, width: 190 },
        { text: window.strings['contact'], value: 'mobile_phone', align: 'center', width: 150 },
        { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 100 },
      ]
      const headers = []
      for (let h = 0; h < defaultHeaders.length; h++) {
        let pushHeader = true
        if (defaultHeaders[h].value === 'photo' && !showPhotos) {
          pushHeader = false
        }
        if (defaultHeaders[h].value === 'payday' && !paydayVisible) {
          pushHeader = false
        }
        if (defaultHeaders[h].value === 'feedback_day' && !feedbackDayVisible) {
          pushHeader = false
        }
        if (defaultHeaders[h].value === 'chat_last_msg' && !hasChat) {
          pushHeader = false
        }
        if (defaultHeaders[h].value === 'tags' && !showTags) {
          pushHeader = false
        }
        if (defaultHeaders[h].value === 'employee1' && !showAssignEmployees) {
          pushHeader = false
        }
        if (!Utils.hasPermission('client_' + defaultHeaders[h].value + '_view')) {
          pushHeader = false
        }
        if (pushHeader) {
          headers.push(defaultHeaders[h])
        }
      }

      // New client modal fields
      const newClientFields = [{
        type: 'input',
        id: 'name',
        title: window.strings['name'],
        value: '',
        visible: true,
      }, {
        type: 'input',
        id: 'email',
        title: window.strings['email'],
        value: '',
        visible: true,
      }, {
        type: 'datepicker',
        id: 'birth_date',
        title: window.strings['birth_date'],
        value: '',
        visible: true,
      }, {
        type: 'select',
        id: 'gender',
        title: window.strings['gender'],
        items: Utils.getGenders(),
        value: '',
        visible: true,
      }, {
        type: 'input',
        id: 'mobile_phone',
        title: window.strings['contact'],
        value: '',
        visible: true,
      }, {
        type: 'datepickerPayday',
        id: 'payday',
        title: window.strings['payment_date'],
        value: '',
        visible: paydayVisible,
      }, {
        type: 'datepickerFeedbackDay',
        id: 'feedback_day',
        title: window.strings['feedback_date'],
        value: '',
        visible: feedbackDayVisible,
      }]

      if (user && user.configurations && user.configurations.payment && user.configurations.payment.paym_pri) {
        newClientFields.push({
          type: 'select',
          id: 'paym_pri',
          title: window.strings['client_plan'],
          items: [],
          value: '',
          visible: true,
        })
      }

      if (!window.storageClients) {
        window.storageClients = {}
      }

      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        hasPermission: Utils.hasPermission,
        user: user,
        isEmployee: isEmployee,
        hasChat: hasChat,
        hasChatV2: hasChatV2,
        showPhotos: showPhotos,
        showAssignEmployees: showAssignEmployees,
        showSendChatMessages: showSendChatMessages,
        showSendNotifications: showSendNotifications,
        showExportPayments: showExportPayments,
        showTags: showTags,
        showWhatsapp: showWhatsapp,
        showWhatsappApi: showWhatsappApi,
        showUpdateToNewRegister: showUpdateToNewRegister,
        showPaymPri: showPaymPri,
        showPaymError: showPaymError,
        paymentType: paymentType,
        status2Enabled: status2Enabled,
        showBlockClients: showBlockClients,
        filterEmployee: filterEmployee,
        filterEmployeeCheckbox: filterEmployeeCheckbox,
        ignoreSearchAccents: ignoreSearchAccents,
        exportWithRegisters: exportWithRegisters,
        notificationsModes: notificationsModes,
        keepNotificationModesModalOpened: keepNotificationModesModalOpened,
        clientsStatusDict: this.getClientsStatusDict(),
        feedbackWeekdays: Utils.getWeekdays(),
        feedbackFrequencies: Utils.getFeedbackFrequencies(),
        moment: moment,
        currentDate: moment().format('YYYY-MM-DD'),
        tableItemsPerPage: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.clients ? window.cacheTableItemsPerPage.clients : 10,
        clients: [],
        cacheClients: {},
        currentPage: 1,
        dialogClient: false,
        datepickerMenu: false,
        datepickerPaydayMenu: false,
        datepickerFeedbackDayMenu: false,
        newClientData: newClientFields,
        search: '',
        searchTemp: '',
        searchPromise: null,
        headers: headers,
        filtersDatepickerMenu: false,
        filtersDatepickerDates: [Utils.getFormatDate(new Date(Date.now() - 86400000)), Utils.getFormatDate()],
        filtersSingleDatepickerDate: Utils.getFormatDate(),
        statusMap: {
          active: 0,
          suspended: 1,
          blocked: 2,
          newRegister: 3,
        },
        filters: [],
        filterHasDatepicker: {},
        filterHasExportCsv: {},
        filterHasInputs: {},
        filterHasPagination: {},
        filterHasInputSearch: {},
        filterHasInfo: {},
        filterHasPaymentsPacks: {},
        statusFilter: null,
        statusFilterItems: [],
        dialogSendMessage: false,
        sendMessageHeaders: [
          { text: '', align: 'center', value: 'checkbox', align: 'left', width: 65 },
          { text: window.strings['id'], value: 'id', align: 'left', width: 90 },
          { text: window.strings['name'], value: 'name', align: 'left', width: 140 },
          { text: window.strings['email'], value: 'email', align: 'left', width: 180 },
          { text: window.strings['nutrition'], value: 'has_food_plan', align: 'center', width: 110 },
          { text: window.strings['training'], value: 'has_training_plan', align: 'center', width: 85 },
          { text: '', value: 'actions', align: 'center', width: 80 },
        ],
        sendMessageSearch: '',
        sendMessageItemsPerPage: 10,
        message: {
          text: '',
          clients: [],
          selectedAll: false,
        },
        sendMessageTableLoading: false,
        employeesDict: {},
        dialogExportPayments: false,
        exportPaymentsDatepickerMenu: false,
        exportPaymentsDatepickerDates: [Utils.getFormatDate(new Date(Date.now() - 2592000000)), Utils.getFormatDate()],
        tagsDict: this.getUserTagsDict(),
        dialogSendNotifications: false,
        notificationsToSend: null,
        dialogBlockClients: false,
        blockClientsList: [],
        blockClientsSearchInput: '',
        tableTotalItems: null,
        isTableLoading: false,
        dialogSelectClientWhatsappApi: false,
        dialogClientWhatsappApi: false,
        dialogClientWhatsappApiMessage: false,
        clientWhatsappApiFields: [],
        dialogNotificationModes: false,
        paymentsPacks: [],
        paymentsPacksFilter: null,
      }
    },
    computed: {
      filtersDatepickerText() {
        return this.filtersDatepickerDates.join(' / ')
      },
      filtersSingleDatepickerText() {
        return this.filtersSingleDatepickerDate
      },
      sendMessageSelectedClients() {
        if (this.message.clients) {
          const list = this.message.clients.filter(function (it) {
            return it.selected
          })
          return list.map(a => a.id + ' - ' + a.name)
        }
        return []
      },
      exportPaymentsDatepickerDatesText() {
        return this.exportPaymentsDatepickerDates.join(' / ')
      },
      intervalFilterDisabled() {
        const min = parseInt(this.filterHasInputs[this.statusFilter].min)
        if (this.filterHasInputs[this.statusFilter].max !== false) {
          const max = parseInt(this.filterHasInputs[this.statusFilter].max)
          return isNaN(min) || isNaN(max) || min > max
        }
        return isNaN(min)
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }

      this.setFilters()
      this.refreshClientsList(true)
      if (this.user && this.user.configurations && this.user.configurations.payment && this.user.configurations.payment.paym_pri) {
        this.setClientPaymPriPlans()
      }
      if (this.showAssignEmployees) {
        this.getEmployees()
      }
      this.subscribeMqttTopic()
    },
    methods: {
      changePage: function (page) {
        this.currentPage = page
        if (this.filterHasPagination[this.statusFilter] && !this.isTableLoading) {
          this.getClients()
        }
      },
      onTableAscSort: function (field) {
        this.applyTableSort(field, 'asc')
      },
      onTableDescSort: function (field) {
        this.applyTableSort(field, 'desc')
      },
      applyTableSort: function (field, mode) {
        if (this.filterHasPagination[this.statusFilter] && !this.isTableLoading) {
          if (field) {
            if (typeof field === 'boolean') {
              this.tableSort[0].mode = mode
            } else {
              this.tableSort = [{
                mode: mode,
                field: field
              }]
            }
          } else {
            this.tableSort = null
          }
          this.getClients()
        }
      },
      updateTableItemsPerPage: function (value) {
        if (!window.cacheTableItemsPerPage) {
          window.cacheTableItemsPerPage = {}
        }
        window.cacheTableItemsPerPage.clients = value
      },
      getClientsStatusDict: function () {
        const statusList = Utils.getUserStatus()
        const dict = {}
        if (statusList && statusList.length) {
          statusList.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getUserTagsDict: function () {
        const statusList = Utils.getUserTags()
        const dict = {}
        if (statusList && statusList.length) {
          statusList.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      onSearch: function () {
        const self = this
        if (this.filterHasInputSearch[this.statusFilter]) {
          this.search = this.searchTemp
        } else {
          clearTimeout(this.searchPromise)
          this.searchPromise = setTimeout(function () {
            self.search = self.searchTemp
            self.applyTableSearch()
          }, 500)
        }
      },
      applyTableSearch: function () {
        if (this.filterHasPagination[this.statusFilter] && !this.filterHasInputSearch[this.statusFilter]) {
          this.getClients()
        }
      },
      customTableSearch: function (value, search, item) {
        const normalize = str => str ? str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
        const [col, val] = search.toLowerCase().split('=')
        if (col && val) {
          const itemValue = item[col] ? item[col].toString() : ''
          return itemValue ? normalize(itemValue).includes(normalize(val)) : false
        }
        const searchText = this.ignoreSearchAccents ? normalize(search) : search.toLowerCase()
        return this.headers.some(header => {
          const itemValue = item[header.value] ? item[header.value].toString() : ''
          return itemValue ? (this.ignoreSearchAccents ? normalize(itemValue).includes(searchText) : itemValue.toLowerCase().includes(searchText)) : false
        })
      },
      sortDateField: function (a, b) {
        let dateA = a
        let dateB = b
        if (!dateA) {
          dateA = '1994-01-01 00:00:00'
        }
        if (!dateB) {
          dateB = '1994-01-01 00:00:00'
        }
        return dateA.localeCompare(dateB)
      },
      setFilters: function () {
        const self = this
        const filters = []
        const items = []
        const filtersDatepickers = {}
        const filtersExportCsv = {}
        const filtersInputs = {}
        const filtersPagination = {}
        const filtersInputSearch = {}
        const filterHasInfo = {}
        const filterHasPaymentsPacks = {}

        if (this.user && this.user.configurations && this.user.configurations.client_list) {
          for (let i = 0; i < this.user.configurations.client_list.length; i++) {
            const item = this.user.configurations.client_list[i]
            const permission = item.permission ? item.permission : item.id

            if (!Utils.hasPermission(permission)) {
              continue
            }

            let requestObject = {
              name: 'getClients',
              payload: {
                status: self.statusMap.active
              }
            }
            if (item.query) {
              requestObject = {
                name: 'getClientsWithQuery',
                payload: {
                  query: item.query
                }
              }
            } else {
              if (item.id === 'suspended_clients') {
                requestObject = {
                  name: 'getClients',
                  payload: {
                    status: self.statusMap.suspended
                  }
                }
              }
              if (item.id === 'need_motivation_clientes') {
                requestObject = {
                  name: 'getClients',
                  payload: {
                    status: self.statusMap.active,
                    status2: '%23FF0000'
                  }
                }
              }
            }

            filters.push({
              id: item.id,
              label: window.strings[item.title] ? window.strings[item.title] : item.title,
              datepicker: item.datepicker,
              defaultDates: item.defaultDates,
              notAllowFutureDates: item.notAllowFutureDates,
              disableCache: item.disableCache,
              mqttRefresh: item.mqttRefresh,
              inputs: item.inputs,
              filters: item.filters,
              query: item.query,
              queryNextClient: item.query_next_client,
              queryPreviousClient: item.query_previous_client,
              pagination: item.pagination,
              inputSearch: item.inputSearch,
              paymentsPacks: item.payments_packs,
              info: item.info,
              filterId: item.filter_id,
              request: requestObject
            })

            items.push({
              value: item.id,
              label: window.strings[item.title] ? window.strings[item.title] : item.title,
            })

            filtersDatepickers[item.id] = item.datepicker ? {
              datepicker: item.datepicker,
              notAllowFutureDates: item.notAllowFutureDates
            } : false
            filtersExportCsv[item.id] = item.exportCsv ? true : false
            filtersInputs[item.id] = item.inputs
            filtersPagination[item.id] = item.pagination
            filtersInputSearch[item.id] = item.inputSearch
            filterHasInfo[item.id] = item.info ? true : false
            filterHasPaymentsPacks[item.id] = item.payments_packs ? true : false
          }
        }

        this.filters = filters
        this.filterHasDatepicker = filtersDatepickers
        this.filterHasExportCsv = filtersExportCsv
        this.filterHasInputs = filtersInputs
        this.filterHasPagination = filtersPagination
        this.filterHasInputSearch = filtersInputSearch
        this.filterHasInfo = filterHasInfo
        this.filterHasPaymentsPacks = filterHasPaymentsPacks
        this.statusFilterItems = items

        let statusFilterDefault = items.length ? items[0].value : null
        if (window.cacheTables && window.cacheTables.clients) {
          if (window.cacheTables.clients.filter) {
            const statusFilterCache = items.find(function (it) {
              return it.value === window.cacheTables.clients.filter
            })
            if (statusFilterCache) {
              statusFilterDefault = statusFilterCache.value
            }
          }
          if (window.cacheTables.clients.page) {
            this.currentPage = window.cacheTables.clients.page
          }
          delete window.cacheTables.clients
        }
        this.statusFilter = statusFilterDefault
      },
      applyRefreshListFilter: function () {
        const self = this
        this.filtersDatepickerMenu = false
        this.resetClientsCache()
        const filter = this.filters.find(function (item) {
          return item.id === self.statusFilter
        })
        if (filter && filter.defaultDates) {
          filter.defaultDates = null
        }
        this.refreshClientsList()
      },
      resetClientsCache: function () {
        for (let i = 0; i < this.filters.length; i++) {
          if (this.filters[i].datepicker) {
            delete this.cacheClients[this.filters[i].id]
            delete window.storageClients[this.filters[i].id]
          }
          if (this.filters[i].inputs) {
            delete this.cacheClients[this.filters[i].id]
            delete window.storageClients[this.filters[i].id]
          }
        }
      },
      setClientPaymPriPlans: function () {
        const self = this
        Api.getSubscriptionPlans(function (response) {
          if (response.success) {
            for (let i = 0; i < self.newClientData.length; i++) {
              if (self.newClientData[i].id === 'paym_pri') {
                self.newClientData[i].items = []
                for (let r = 0; r < response.data.length; r++) {
                  if (response.data[r].prices && response.data[r].prices[0]) {
                    self.newClientData[i].items.push({
                      value: response.data[r].prices[0].id,
                      label: response.data[r].name,
                    })
                  }
                }
                break
              }
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      statusFilterSubFilters: function () {
        const self = this
        const filter = this.filters.find(function (item) {
          return item.id === self.statusFilter
        })
        if (filter && filter.filters) {
          return filter.filters
        }
        return false
      },
      refreshClientsList: function (initialize, noLoading) {
        this.checkFilterDefaultDates()
        if (this.cacheClients[this.statusFilter]) {
          this.clients = this.cacheClients[this.statusFilter]
        } else {
          if (window.storageClients && window.storageClients[this.statusFilter]) {
            this.clients = window.storageClients[this.statusFilter]
          } else if (!noLoading) {
            this.$isLoading(true)
          }
          if (this.filterHasPagination[this.statusFilter]) {
            this.isTableLoading = true
            if (!initialize) {
              this.currentPage = 1
            }
          }
          if (this.filterHasPaymentsPacks[this.statusFilter]) {
            this.getPaymentsPacks()
          }
          this.getClients()
        }
        if (!initialize && !this.filterHasPagination[this.statusFilter]) {
          this.currentPage = 1
        }
      },
      checkFilterDefaultDates: function () {
        const self = this
        const filter = this.filters.find(function (item) {
          return item.id === self.statusFilter
        })
        let resetCache = false
        if (filter && filter.defaultDates) {
          if (filter.defaultDates.indexOf('last_days') > -1 || filter.defaultDates.indexOf('next_days') > -1) {
            const daysNumber = parseInt(filter.defaultDates.replace(/\D/g, ''))
            const today = new Date()
            const defaultDate = new Date(today)
            if (filter.defaultDates.indexOf('next_days') > -1) {
              defaultDate.setDate(today.getDate() + daysNumber);
            } else {
              defaultDate.setDate(today.getDate() - daysNumber);
            }
            const daysDate = Utils.getFormatDate(defaultDate)
            if (filter.datepicker === 'single') {
              this.filtersSingleDatepickerDate = daysDate
            } else {
              this.filtersDatepickerDates = [daysDate, daysDate]
            }
            resetCache = true
          }
        }
        if (filter && filter.notAllowFutureDates) {
          const today = Utils.getFormatDate()
          if (this.filtersSingleDatepickerDate > today) {
            this.filtersSingleDatepickerDate = today
            resetCache = true
          }
        }
        if (resetCache) {
          this.resetClientsCache()
        }
      },
      getPaymentsPacks: function () {
        const self = this
        if (!this.paymentsPacks.length) {
          Api.getPaymentsPacks({
            status: 0,
            fields: ['id', 'name']
          }, function (response) {
            if (response.success) {
              self.paymentsPacks = response.data
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getClients: function (exportCallback, fields) {
        const self = this
        const filter = this.filters.find(function (item) {
          return item.id === self.statusFilter
        })
        const requestFields = fields ? fields : [
          'db_id',
          'id',
          'chat_id',
          'email',
          'name',
          'password',
          'chat_token',
          'status',
          'payday',
          'paym_pri',
          'feedback_day',
          'purchase_date',
          'register_date',
          'last_login_dt',
          'chat_last_msg',
          'mobile_phone',
          'employee1',
          'employee2',
          'flags',
        ]
        if (!fields && this.status2Enabled) {
          requestFields.push('status2')
        }
        if (!fields && this.showTags) {
          requestFields.push('tags')
        }
        if (this.paymentType && this.paymentType.client_paym_error && this.paymentType.client_paym_error.field && requestFields.indexOf(this.paymentType.client_paym_error.field) === -1) {
          requestFields.push(this.paymentType.client_paym_error.field)
        }
        if ((this.paymentType && this.paymentType.client_paym_error) || this.showPaymError) {
          requestFields.push('paym_error')
        }
        if (this.hasChatV2) {
          requestFields.push('chat_user_id')
        }
        const searchFields = ['id', 'name', 'email', 'mobile_phone']
        if (!exportCallback) {
          this.isTableLoading = true
        }

        if (filter) {
          const payloadClone = JSON.parse(JSON.stringify(filter.request.payload))
          if (payloadClone.query) {
            payloadClone.query = this.encodeRequestQuery(filter, payloadClone.query)
          }
          payloadClone.fields = requestFields
          payloadClone.filterEmployee = this.filterEmployee
          if (filter.pagination && !exportCallback) {
            payloadClone.page = this.currentPage
            payloadClone.limit = this.tableItemsPerPage
            if (this.tableSort) {
              payloadClone.order = this.tableSort
            }
            if (this.search) {
              payloadClone.search = {
                input: this.search,
                fields: searchFields
              }
            }
          }
          if (filter.filterId) {
            payloadClone.filterId = filter.id
          }
          if (exportCallback && self.exportWithRegisters) {
            payloadClone.registers = true
          }
          if (filter.id === 'suspended_clients') {
            payloadClone.fields.push('status_date')
          }
          if (filter.paymentsPacks && this.paymentsPacksFilter) {
            payloadClone.payment_pack = this.paymentsPacksFilter
          }
          Api[filter.request.name](payloadClone, function (response) {
            afterRequest(response)
          })
        } else {
          Api.getClients({
            status: this.statusMap.active,
            fields: requestFields,
            filterEmployee: this.filterEmployee
          }, function (response) {
            afterRequest(response)
          })
        }

        function afterRequest(response) {
          const clients = []
          const ids = []
          if (!exportCallback) {
            self.$isLoading(false)
          }
          if (response.success) {
            if (exportCallback) {
              return exportCallback(response.data)
            }
            for (let i = 0; i < response.data.length; i++) {
              const client = Utils.encodeClient(response.data[i], true)
              const clientId = response.data[i].id
              if (window.cachePhotos['client-' + clientId]) {
                client.photo = window.cachePhotos['client-' + clientId]
              } else {
                ids.push(clientId)
              }
              clients.push(client)
            }
            const filteredClients = filterClients(clients)
            if (self.statusFilter === self.statusFilter) {
              self.clients = filteredClients
            }
            if (filter && !filter.filters && !filter.disableCache && !filter.pagination) {
              self.cacheClients[self.statusFilter] = filteredClients
              window.storageClients[self.statusFilter] = filteredClients
            }
            if (filter && filter.pagination) {
              if (response.items_count || response.items_count === 0) {
                self.tableTotalItems = response.items_count
              }
            } else {
              self.tableTotalItems = 0
            }
            if (ids.length > 0 && self.showPhotos) {
              self.getClientsPhotos(ids)
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
          self.isTableLoading = false
        }

        function filterClients(clients) {
          if (filter && filter.filters) {
            const filtersValues = {}
            let hasValue = false
            filter.filters.forEach(function (flt) {
              if (flt.value) {
                hasValue = true
                filtersValues[flt.id] = flt.value
              }
            })
            if (hasValue) {
              clients = clients.filter(function (cl) {
                for (const flt in filtersValues) {
                  if (!cl[flt]) {
                    return false
                  }
                  const diffDays = Math.floor(moment.duration(moment().diff(moment(cl[flt]))).asDays())
                  if (filtersValues[flt] > diffDays) {
                    return false
                  }
                }
                return true
              })
            }
          }
          return clients
        }
      },
      showFilterInfo: function (info) {
        const self = this
        const filter = this.filters.find(function (item) {
          return item.id === self.statusFilter
        })
        if (filter && filter.info) {
          this.$alert(
            this.encodeRequestQuery(filter, filter.info),
            '',
            'info',
            Utils.getAlertOptions()
          )
        }
      },
      changeFilterEmployeeCheckbox: function () {
        window.filterEmployeeCheckboxCache = this.filterEmployee
        this.resetClientsList()
      },
      encodeRequestQuery: function (filter, query) {
        const today = moment().format('YYYY-MM-DD')
        let lastWeek = moment().subtract(7, 'days').format('YYYY-MM-DD')

        query = replaceQueryKey(query, '<pt_id>', axios.defaults.ptId)
        query = replaceQueryKey(query, '<today_hours>', today + ' 00:00:00')
        query = replaceQueryKey(query, '<today>', today)

        if (filter.datepicker) {
          const dates = filter.datepicker === 'single' ? {
            start: this.filtersSingleDatepickerDate
          } : {
            start: this.filtersDatepickerDates[0] + ' 00:00:00',
            end: this.filtersDatepickerDates[1] + ' 23:59:59',
          }
          query = replaceQueryKey(query, '<start_date>', dates.start)
          query = replaceQueryKey(query, '<end_date>', dates.end)

          if (query.includes('start_date_time')) {
            if (filter.datepicker === 'single') {
              query = replaceQueryKey(query, '<start_date_time>', this.filtersSingleDatepickerDate + ' 00:00:00')
              query = replaceQueryKey(query, '<end_date_time>', this.filtersSingleDatepickerDate + ' 23:59:59')
            } else {
              query = replaceQueryKey(query, '<start_date_time>', dates.start)
              query = replaceQueryKey(query, '<end_date_time>', dates.end)
            }
          }

          lastWeek = moment(this.filtersSingleDatepickerDate).subtract(7, 'days').format('YYYY-MM-DD')
        }

        query = replaceQueryKey(query, '<tz>', window.diffUtcHours)
        query = replaceQueryKey(query, '<last_week>', lastWeek)

        if (query.includes('<month_day')) {
          const splitDatepickerDate = filter.datepicker ? this.filtersSingleDatepickerDate.split('-') : null
          query = replaceQueryKey(query, '<month_day>', filter.datepicker ? (splitDatepickerDate[1] + '-' + splitDatepickerDate[2]) : moment().format('MM-DD'))
        }

        if (query.includes('<previous_days')) {
          const regex = /<previous_days_(\d+)>/g
          let match = null
          while ((match = regex.exec(query)) !== null) {
            const dateRef = filter.datepicker === 'single' ? this.filtersSingleDatepickerDate : this.filtersDatepickerDates[0];
            const dayRef = filter.datepicker ? moment(dateRef) : moment()
            const previousDays = parseInt(match[1])
            query = query.replace(match[0], dayRef.subtract(previousDays, 'days').format('YYYY-MM-DD'))
          }
        }

        if (query.includes('<next_days')) {
          const regex = /<next_days_(\d+)>/g
          let match = null
          while ((match = regex.exec(query)) !== null) {
            const dateRef = filter.datepicker === 'single' ? this.filtersSingleDatepickerDate : this.filtersDatepickerDates[0];
            const dayRef = filter.datepicker ? moment(dateRef) : moment()
            const nextDays = parseInt(match[1])
            query = query.replace(match[0], dayRef.add(nextDays, 'days').format('YYYY-MM-DD'))
          }
        }

        if (query.includes('<today_previous_days')) {
          const regex = /<today_previous_days_(\d+)>/g
          let match = null
          while ((match = regex.exec(query)) !== null) {
            const dayRef = moment()
            const previousDays = parseInt(match[1])
            query = query.replace(match[0], dayRef.subtract(previousDays, 'days').format('YYYY-MM-DD'))
          }
        }

        if (query.includes('<today_next_days')) {
          const regex = /<today_next_days_(\d+)>/g
          let match = null
          while ((match = regex.exec(query)) !== null) {
            const dayRef = moment()
            const nextDays = parseInt(match[1])
            query = query.replace(match[0], dayRef.add(nextDays, 'days').format('YYYY-MM-DD'))
          }
        }

        if (query.includes('<input_min>') && this.filterHasInputs[this.statusFilter]) {
          query = replaceQueryKey(query, '<input_min>', parseInt(this.filterHasInputs[this.statusFilter].min))
        }

        if (query.includes('<input_max>') && this.filterHasInputs[this.statusFilter]) {
          query = replaceQueryKey(query, '<input_max>', parseInt(this.filterHasInputs[this.statusFilter].max))
        }

        if (query.includes('<days_calcule>') && this.filterHasInputs[this.statusFilter]) {
          const inputMin = parseInt(this.filterHasInputs[this.statusFilter].min)
          const inputMax = parseInt(this.filterHasInputs[this.statusFilter].max)
          const inputCalcule = ((inputMax - inputMin) * 14) / 2
          const dayRef = filter.datepicker ? moment(this.filtersSingleDatepickerDate) : moment()
          query = replaceQueryKey(query, '<days_calcule>', dayRef.add(inputCalcule, 'days').format('YYYY-MM-DD'))
        }

        return query

        function replaceQueryKey(string, search, replace) {
          return string.replace(new RegExp(search, 'g'), replace)
        }
      },
      getClientsPhotos: function (clientsIds) {
        const self = this
        const splitIds = Utils.splitArray(clientsIds, 5)

        getPhotos(0)

        function getPhotos(index) {
          if (splitIds[index] && self.$route.path === '/home/clients') {
            Api.getClientsPhotos(splitIds[index], function (response) {
              setPhotos(response, splitIds[index])
              getPhotos(index + 1)
            })
          }
        }

        function setPhotos(response, ids) {
          const photos = {}

          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              photos[response.data[i].id] = response.data[i].photo
            }
          }

          for (let i = 0; i < self.clients.length; i++) {
            const id = self.clients[i].id

            if (ids.indexOf(id) > -1) {
              const newPhoto = photos[id] ? photos[id] : require('@/assets/user.svg')
              window.cachePhotos['client-' + id] = newPhoto
              self.clients[i].photo = newPhoto
            }
          }
        }
      },
      getClientFeedbackDay: function (day) {
        if (this.user && this.user.configurations && this.user.configurations.feedback && this.user.configurations.feedback.feedback_weekday && day.indexOf('#') > -1) {
          const splitDay = day.split('#')
          const dayToCompare = splitDay[1]
          const dayItem = this.feedbackWeekdays.find(function (item) {
            return item.value === dayToCompare
          })
          const frequencyToCompare = splitDay[2]
          const frequencyItem = this.feedbackFrequencies.find(function (item) {
            return item.value === frequencyToCompare
          })
          let feedbackDayLabel = ''
          if (dayItem) {
            feedbackDayLabel += dayItem.label
          }
          if (frequencyItem) {
            feedbackDayLabel += ' - ' + frequencyItem.label
          }
          return feedbackDayLabel
        }
        return day
      },
      openClient: function (client) {
        const self = this
        if (!Utils.hasPermission('client_list_open_client')) {
          return true
        }
        this.$isLoading(true)
        Api.getUserWithId({
          id: client.id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const client = Utils.encodeClient(response.data[0])
            Utils.setStorage('client', client)
            window.showPaydayAlert = true
            window.cacheTables = window.cacheTables ? window.cacheTables : {}
            window.cacheTables.clients = {
              page: self.currentPage,
              filter: self.statusFilter
            }
            const filter = self.filters.find(function (item) {
              return item.id === self.statusFilter
            })
            if (filter && filter.queryNextClient && filter.queryPreviousClient) {
              window.clientPageCacheList = {
                queryNextClient: self.encodeRequestQuery(filter, filter.queryNextClient),
                queryPreviousClient: self.encodeRequestQuery(filter, filter.queryPreviousClient)
              }
            } else {
              const clientsClone = JSON.parse(JSON.stringify(self.clients))
              window.clientPageCacheList = clientsClone.sort((a, b) => a.id - b.id)
            }
            self.$router.navigate.push({ path: '/home/client', query: { tab: 'details' } })
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      activeClient(client) {
        const self = this
        this.$confirm(
          window.strings['want_activate_client'] + (client ? (' "' + client.name + '"') : '') + '?',
          '',
          'success',
          Utils.getAlertOptions(true)
        ).then(() => {
          self.updateUser(client, self.statusMap.active)
        }).catch(() => { })
      },
      suspendClient(client) {
        const self = this
        this.$confirm(
          window.strings['want_suspend_client'] + (client ? (' "' + client.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.updateUser(client, self.statusMap.suspended)
        }).catch(() => { })
      },
      blockClient(client) {
        const self = this
        this.$confirm(
          window.strings['want_block_client'] + (client ? (' "' + client.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.updateUser(client, self.statusMap.blocked)
        }).catch(() => { })
      },
      openNewClient() {
        for (let i = 0; i < this.newClientData.length; i++) {
          this.newClientData[i].value = ''
        }
        this.dialogClient = true
      },
      cancelNewClient() {
        this.closeClient()
      },
      saveNewClient() {
        if (this.isClientValid()) {
          this.newClient()
        } else {
          this.$alert(
            window.strings['fields_to_be_filled'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      },
      closeClient() {
        this.dialogClient = false
      },
      isClientValid: function () {
        for (let i = 0; i < this.newClientData.length; i++) {
          if (this.newClientData[i].visible && !this.newClientData[i].value) {
            return false
          }
        }
        return true
      },
      newClient() {
        const self = this
        const client = {
          type: 1,
          password: '',
        }

        this.$isLoading(true)

        for (let i = 0; i < this.newClientData.length; i++) {
          if (this.newClientData[i].id === 'email') {
            client[this.newClientData[i].id] = Utils.encodeEmail(this.newClientData[i].value)
          } else {
            client[this.newClientData[i].id] = this.newClientData[i].value
          }
        }

        Api.newClient(client, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.closeClient()
            self.resetClientsList()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      resetClientsList(keepPage) {
        this.cacheClients = {}
        window.storageClients = {}
        this.refreshClientsList(keepPage)
      },
      resetClientsListActiveFilter() {
        delete this.cacheClients[this.statusFilter]
        delete window.storageClients[this.statusFilter]
        this.refreshClientsList(false, true)
      },
      updateUser(client, status) {
        const self = this
        Api.updateUser({
          id: client.id,
          status: status,
        }, function (response) {
          if (response.success) {
            self.resetClientsList()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      exportClients(onlyId) {
        const self = this
        const filter = this.filters.find(function (item) {
          return item.id === self.statusFilter
        })
        let fields = ['id', 'name', 'email', 'mobile_phone', 'payday', 'feedback_day', 'purchase_date', 'register_date', 'last_login_dt']
        if (filter && filter.id === 'suspended_clients') {
          fields.push('status_date')
        }
        if (!this.exportWithRegisters && onlyId) {
          fields = ['id']
        }
        if (this.exportWithRegisters) {
          fields = ['db_id', 'id', 'email', 'name', 'payday', 'feedback_day', 'purchase_date', 'register_date', 'last_login_dt', 'chat_last_msg', 'mobile_phone']
        }
        if (this.filterHasPagination[this.statusFilter] || this.exportWithRegisters) {
          this.$isLoading(true)
          this.getClients(function (clients) {
            self.$isLoading(false)
            self.exportClientsCsv(clients, fields)
          }, fields)
        } else {
          this.exportClientsCsv(this.clients, fields)
        }
      },
      exportClientsCsv(clients, fields) {
        try {
          const fieldsAvailable = {
            id: window.strings['id'],
            email: window.strings['email'],
            name: window.strings['name'],
            payday: window.strings['payment_date'],
            feedback_day: window.strings['feedback_date'],
            purchase_date: window.strings['registration_created_date'],
            register_date: window.strings['registration_validated_date'],
            last_login_dt: window.strings['last_login_date'],
            chat_last_msg: window.strings['last_message_date'],
            mobile_phone: window.strings['contact'],
            status_date: window.strings['suspension_date'],
          }
          const headers = []
          for (let f = 0; f < fields.length; f++) {
            if (fieldsAvailable[fields[f]]) {
              headers.push({
                value: fields[f],
                text: fieldsAvailable[fields[f]]
              })
            }
          }
          if (this.exportWithRegisters && this.exportWithRegisters.questions) {
            this.exportWithRegisters.questions.forEach(function (q) {
              headers.push({
                parent: 'questions',
                value: q.id,
                text: window.strings[q.title] ? window.strings[q.title] : q.title
              })
            })
          }
          Utils.exportCsv({
            title: window.strings['clients'],
            headers: headers,
            data: clients
          })
        } catch { }
      },
      openSendMessage() {
        if (this.clients.length) {
          this.$isLoading(true)
          return this.getClients((clients) => {
            this.$isLoading(false)
            const list = []
            clients.forEach(function (client) {
              list.push({
                id: client.id,
                name: client.name,
                email: client.email,
                chat_id: client.chat_id,
                chat_user_id: client.chat_user_id,
                chat_token: client.chat_token,
                password: client.password,
                has_training_plan: (client.flags & (1 << 7)) !== 0,
                has_food_plan: (client.flags & (1 << 8)) !== 0,
                selected: false,
                has_unread_messages: false
              })
            })
            if (list.length) {
              this.message = {
                text: '',
                clients: list,
                selectedAll: false
              }
              this.dialogSendMessage = true
              return true
            }
          })
        }
        this.$alert(
          window.strings['empty_clients_list'],
          '',
          'warning',
          Utils.getAlertOptions()
        )
      },
      refreshSendMessageClients(table) {
        const self = this

        if (table) {
          this.sendMessageTableCache = table
        }

        setTimeout(function () {
          let chatUsersIds = []

          self.$refs.sendMessageTableRef.selectableItems.forEach(function (item) {
            if (self.hasChatV2) {
              if (item.chat_user_id) {
                chatUsersIds.push(item.chat_user_id)
              }
            } else {
              if (item.chat_id) {
                chatUsersIds.push(item.chat_id)
              }
            }
          })

          if (chatUsersIds.length) {
            chatUsersIds = [...new Set(chatUsersIds)]
            if (self.hasChatV2) {
              getChatV2Users()
            } else {
              getChatRooms()
            }
          }

          function getChatV2Users() {
            ChatApi.getChatV2Users({
              ids: chatUsersIds
            }, function (response) {
              if (response.success) {
                const startIndex = (self.sendMessageTableCache.page - 1) * self.sendMessageTableCache.itemsPerPage;
                const endIndex = startIndex + self.sendMessageTableCache.itemsPerPage - 1;
                for (let c = startIndex; c < endIndex + 1; c++) {
                  if (self.message.clients[c]) {
                    const chatUser = self.message.clients[c].chat_user_id && response.data && response.data.results ? response.data.results.find(u => {
                      return u.id === self.message.clients[c].chat_user_id
                    }) : null
                    self.message.clients[c].chat_room_id = chatUser && chatUser.chatId ? chatUser.chatId : null
                    self.message.clients[c].has_unread_messages = chatUser && chatUser.lastMessage && (!chatUser.lastMessage.userIsStaff || chatUser.lastMessage.auto) ? true : false
                  }
                }
                self.sendMessageTableLoading = false
              } else {
                self.sendMessageTableLoading = false
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }

          function getChatRooms() {
            self.sendMessageTableLoading = true
            ChatApi.getChatRoomsList({
              ids: chatUsersIds,
              fields: ['id', 'last_msg_user_id', 'auto']
            }, function (response) {
              if (response.success) {
                const lastMsgUsersIds = []
                const autoMessages = {}
                const ptChatUserId = 1
                response.data.forEach(function (it) {
                  if (it.last_msg_user_id > ptChatUserId && lastMsgUsersIds.indexOf(it.last_msg_user_id) === -1) {
                    lastMsgUsersIds.push(it.last_msg_user_id)
                  }
                  const userId = it.users ? it.users.find(function (us) {
                    return us !== ptChatUserId
                  }) : null
                  if (userId) {
                    autoMessages[userId] = it.auto
                  }
                })
                const startIndex = (self.sendMessageTableCache.page - 1) * self.sendMessageTableCache.itemsPerPage;
                const endIndex = startIndex + self.sendMessageTableCache.itemsPerPage - 1;
                for (let c = startIndex; c < endIndex + 1; c++) {
                  if (self.message.clients[c]) {
                    const clientChatId = self.message.clients[c].chat_id
                    if (lastMsgUsersIds.indexOf(clientChatId) > -1 || autoMessages[clientChatId]) {
                      self.message.clients[c].has_unread_messages = true
                    } else {
                      self.message.clients[c].has_unread_messages = false
                    }
                  }
                }
                self.sendMessageTableLoading = false
              } else {
                self.sendMessageTableLoading = false
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        })
      },
      selectSendMessageClient(item) {
        let counter = 0
        if (!item.selected) {
          this.message.clients.forEach(function (it) {
            if (it.selected) {
              counter += 1
            }
          })
          if (counter >= this.sendMessageItemsPerPage) {
            return this.$alert(
              window.strings['send_message_all_warning'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        }
        item.selected = !item.selected
        this.refreshSelectedAllStatus()
      },
      selectAllSendMessageClients() {
        const self = this
        const status = !this.message.selectedAll ? true : false
        let counter = 0
        if (status) {
          this.message.clients.forEach(function (it) {
            if (it.selected) {
              counter += 1
            }
          })
          if (counter >= this.sendMessageItemsPerPage) {
            return this.$alert(
              window.strings['send_message_all_warning'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        }
        for (let i = 0; i < this.$refs.sendMessageTableRef.selectableItems.length; i++) {
          if (counter >= this.sendMessageItemsPerPage) {
            break
          }
          const index = this.message.clients.findIndex(function (cl) {
            return cl.id === self.$refs.sendMessageTableRef.selectableItems[i].id
          })
          if (index > -1) {
            this.message.clients[index].selected = status
          }
          if (status) {
            counter += 1
          }
        }
        this.refreshSelectedAllStatus()
      },
      refreshSelectedAllStatus() {
        let counter = 0
        this.message.clients.forEach(function (it) {
          if (it.selected) {
            counter += 1
          }
        })
        if (counter >= this.sendMessageItemsPerPage) {
          this.message.selectedAll = true
        } else {
          this.message.selectedAll = false
        }
      },
      closeSendMessage() {
        this.dialogSendMessage = false
        this.message = {
          text: '',
          clients: [],
          selectedAll: false
        }
      },
      sendMessage() {
        const self = this

        if (!this.user.db_id) {
          return this.$alert(
            window.strings['common_error'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }

        const chatIds = []
        const chatClientsToCreate = []
        this.message.clients.forEach(function (client) {
          if (client.selected) {
            if (self.hasChatV2) {
              if (client.chat_room_id) {
                chatIds.push(client.chat_room_id)
              } else {
                chatClientsToCreate.push(client)
              }
            } else {
              if (client.chat_id) {
                chatIds.push(client.chat_id)
              } else {
                chatClientsToCreate.push(client)
              }
            }
          }
        })

        if (chatClientsToCreate.length) {
          if (this.hasChatV2) {
            return this.createChatV2Clients(chatClientsToCreate)
          } else {
            return this.createChatClients(chatClientsToCreate)
          }
        }

        if (!chatIds.length) {
          return this.$alert(
            window.strings['select_clients'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }

        if (!this.message.text) {
          return this.$alert(
            window.strings['please_insert_message'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }

        if (this.hasChatV2) {
          this.$isLoading(true)
          return ChatApi.sendChatV2Messages({
            chat_ids: chatIds,
            message: this.message.text,
            auto: true,
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogSendMessage = false
              self.$alert(
                window.strings['message_sent'],
                '',
                'success',
                Utils.getAlertOptions()
              )
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        this.$isLoading(true)
        getPtChatId(function (ptChatId) {
          getMessages(ptChatId, function (messages) {
            send(messages)
          })
        })

        function getPtChatId(callback) {
          Api.getUserWithDbId({
            id: axios.defaults.ptId,
            fields: ['id', 'chat_id']
          }, function (response) {
            if (response.success) {
              callback(response.data[0].chat_id)
            } else {
              self.$isLoading(false)
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function getMessages(ptChatId, callback) {
          ChatApi.getChatRoomsIds({
            ids: [ptChatId]
          }, function (resp) {
            if (resp.success) {
              const ptRooms = resp.data.map(a => a.chat_id)
              ChatApi.getChatRoomsIds({
                ids: chatIds
              }, function (response) {
                if (response.success) {
                  const msgs = []
                  response.data.forEach(function (it) {
                    if (ptRooms.indexOf(it.chat_id) > -1) {
                      msgs.push({
                        employee_pt_id: self.user.pt_id,
                        employee_id: Math.abs(self.user.id),
                        chat_id: it.chat_id,
                        user_id: ptChatId,
                        type: 0,
                        message: self.message.text,
                        auto: 1
                      })
                    }
                  })
                  callback(msgs)
                } else {
                  self.$isLoading(false)
                  self.$alert(
                    response.message,
                    '',
                    'warning',
                    Utils.getAlertOptions()
                  )
                }
              })
            } else {
              self.$isLoading(false)
              self.$alert(
                resp.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function send(messages) {
          ChatApi.sendChatMessages(messages, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogSendMessage = false
              self.$alert(
                window.strings['message_sent'],
                '',
                'success',
                Utils.getAlertOptions()
              )
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      createChatClients(clients) {
        const self = this
        this.$isLoading(true)
        createChatClient(0)

        async function createChatClient(index) {
          const userObj = clients[index] ? JSON.parse(JSON.stringify(clients[index])) : null

          if (!userObj) {
            self.$isLoading(false)
            self.resetClientsList()
            return self.sendMessage()
          }

          const getPasswordRes = await getPassword()
          if (getPasswordRes === 'error') {
            self.$isLoading(false)
            return self.$alert(
              window.strings['common_error'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
          userObj.password = getPasswordRes

          const newChatClientId = await newUser()
          if (newChatClientId === 'error') {
            self.$isLoading(false)
            return self.$alert(
              window.strings['common_error'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }

          const newChatRes = await newChat()
          if (newChatRes === 'error') {
            self.$isLoading(false)
            return self.$alert(
              window.strings['common_error'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }

          const updateUserRes = await updateUser()
          if (updateUserRes === 'error') {
            self.$isLoading(false)
            return self.$alert(
              window.strings['common_error'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }

          createChatClient(index + 1)

          function getPassword() {
            return new Promise(resolve => {
              Api.decryptPassword(userObj.password, function (response) {
                if (response.success) {
                  resolve(response.data.password)
                } else {
                  resolve('error')
                }
              })
            })
          }

          function newUser() {
            return new Promise(resolve => {
              ChatApi.getChatUserByEmail(userObj.email, function (response) {
                if (response.success) {
                  if (response.data[0]) {
                    resolve(response.data[0].id)
                  } else {
                    newUser()
                  }
                } else {
                  newUser()
                }
              })

              function newUser() {
                ChatApi.newChatUser({
                  token: userObj.chat_token ? userObj.chat_token : '',
                  name: userObj.name,
                  email: userObj.email,
                  password: userObj.password
                }, function (response) {
                  if (response.success) {
                    resolve(response.data.id)
                  } else {
                    resolve('error')
                  }
                })
              }
            })
          }

          function newChat() {
            return new Promise(resolve => {
              ChatApi.getChatRoomsIds({
                ids: [newChatClientId]
              }, function (response) {
                if (response.success) {
                  if (response.data[0]) {
                    resolve(response.data[0])
                  } else {
                    createNewChat()
                  }
                } else {
                  resolve('error')
                }
              })

              function createNewChat() {
                Api.getUserWithDbId({
                  id: axios.defaults.ptId,
                  fields: ['id', 'chat_id']
                }, function (response) {
                  if (response.success) {
                    const ptUser = response.data[0]
                    if (ptUser.chat_id) {
                      ChatApi.newChat({
                        name: userObj.id + ' - ' + userObj.name,
                        type: 0,
                        users: [ptUser.chat_id, newChatClientId]
                      }, function (response) {
                        if (response.success) {
                          resolve(response.data)
                        } else {
                          resolve('error')
                        }
                      })
                    } else {
                      resolve('error')
                    }
                  } else {
                    resolve('error')
                  }
                })
              }
            })
          }

          function updateUser() {
            return new Promise(resolve => {
              Api.updateUser({
                id: userObj.id,
                chat_id: newChatClientId
              }, function (response) {
                if (response.success) {
                  updateLocalClient(self.message.clients)
                  updateLocalClient(self.clients)
                  resolve(response.data)
                } else {
                  resolve('error')
                }
              })
            })

            function updateLocalClient(cls) {
              const index = cls.findIndex(function (c) {
                return c.id === userObj.id
              })
              if (index > -1) {
                cls[index].chat_id = newChatClientId
              }
            }
          }
        }
      },
      createChatV2Clients: function (clients) {
        const self = this
        this.$isLoading(true)
        createChatClient(0)

        function createChatClient(index) {
          if (!clients[index]) {
            return ChatApi.getChatV2Users({
              ids: clients.map(cl => cl.chat_user_id)
            }, function (response) {
              if (response.success) {
                for (let i = 0; i < clients.length; i++) {
                  const chatUser = response.data.results.find(u => {
                    return u.id === clients[i].chat_user_id
                  })
                  if (chatUser && chatUser.chatId) {
                    clients[i].chat_room_id = chatUser.chatId
                  }
                }
                self.$isLoading(false)
                self.resetClientsList()
                self.sendMessage()
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
          Api.updateUser({
            id: clients[index].id,
            name: clients[index].name,
          }, function (response) {
            if (response.success) {
              clients[index].chat_user_id = response.data.chat_user_id
              createChatClient(index + 1)
            } else {
              self.$isLoading(false)
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      openClientChat: function (item) {
        Utils.openClientChat({
          scope: this,
          client: item
        })
      },
      openClientStatus2Warning: function (item) {
        const message = item.status2 === '#FF0000' ?
          'Precisa de muita motivação desiste muito facilmente!' :
          'Tem motivação mas não sabe qual o treino e alimentação certa para conseguir atingir os resultados'
        this.$alert(
          message,
          '',
          'info',
          Utils.getAlertOptions()
        )
      },
      getEmployees: function () {
        const self = this
        this.employeesDict = {}
        Api.getEmployees({
          fields: ['id', 'name']
        }, function (response) {
          if (response.success) {
            const dict = {}
            response.data.forEach(function (item) {
              dict[item.id] = item
            })
            self.employeesDict = dict
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      updateToNewRegisterClient: function (client) {
        const self = this
        this.$confirm(
          window.strings['want_update_to_new_register_client'] + (client ? (' "' + client.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true)
        ).then(() => {
          const config = self.user && self.user.configurations ? self.user.configurations : null
          const data = {
            id: client.id,
            status: this.statusMap.newRegister,
            register_date: moment().format('YYYY-MM-DD'),
            feedback_day: config && config.feedback && config.feedback.feedback_update_days ? moment().add(config.feedback.feedback_update_days, 'days').format('YYYY-MM-DD') : null,
            log_source: 'AUTO-NEW-REGISTER',
          }
          Api.updateUser(data, function (response) {
            if (response.success) {
              self.resetClientsList()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      showInformation: function (message) {
        if (message) {
          this.$alert(
            message,
            '',
            'info',
            Utils.getAlertOptions()
          )
        }
      },
      openExportPayments: function () {
        this.dialogExportPayments = true
      },
      closeExportPayments: function () {
        this.dialogExportPayments = false
      },
      exportPayments: function () {
        const self = this
        this.$isLoading(true)
        Api.exportClientsPayments({
          startDate: this.exportPaymentsDatepickerDates[0],
          endDate: this.exportPaymentsDatepickerDates[1]
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            Utils.exportCsv({
              title: window.strings['payments'],
              content: response.data.csv
            })
            self.closeExportPayments()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openSendNotifications: function () {
        this.notificationsToSend = {
          clients: [],
          title: '',
          body: ''
        }
        this.dialogSendNotifications = true
      },
      closeSendNotifications: function () {
        this.dialogSendNotifications = false
        this.notificationsToSend = null
      },
      sendNotifications: function (all) {
        this.clientsToSendNotification = all ? this.clients.map(a => a.id) : (this.notificationsToSend.clients && this.notificationsToSend.clients.length ? this.notificationsToSend.clients : null)
        if (this.notificationsModes.length > 1) {
          this.dialogNotificationModes = true
        } else {
          switch (this.notificationsModes[0]) {
            case 'push_notification':
              this.sendPushNotification()
              break;
            case 'internal_notification':
              this.sendInternalNotification()
              break;
            case 'email':
              this.sendEmail()
              break;
          }
        }
      },
      sendPushNotification: function () {
        const self = this
        const notification = {
          pt_id: axios.defaults.ptId,
          title: this.notificationsToSend.title,
          body: this.notificationsToSend.body,
        }
        if (this.clientsToSendNotification) {
          notification.users = this.clientsToSendNotification
        }
        this.$isLoading(true)
        Api.sendAppNotification(notification, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.sendNotificationsSuccess()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      sendInternalNotification: function () {
        const self = this
        this.$isLoading(true)
        Api.sendAppManualNotification({
          title: this.notificationsToSend.title,
          body: this.notificationsToSend.body,
          badge: 0,
          type: 2,
          users: this.clientsToSendNotification ? this.clientsToSendNotification : this.clients.map(a => a.id)
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.sendNotificationsSuccess()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      sendEmail: function () {
        const self = this
        const users = []
        this.clients.forEach(function (c) {
          if (!self.clientsToSendNotification || (self.clientsToSendNotification && self.clientsToSendNotification.indexOf(c.id) > -1)) {
            users.push({
              name: c.name,
              email: c.email
            })
          }
        })
        this.$isLoading(true)
        Api.sendEmail({
          subject: this.notificationsToSend.title,
          body: this.notificationsToSend.body,
          users: users
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.sendNotificationsSuccess()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      sendNotificationsSuccess: function () {
        if (!this.keepNotificationModesModalOpened) {
          this.closeNotificationModesDialog()
        }
        this.$alert(
          window.strings['message_sent'],
          '',
          'success',
          Utils.getAlertOptions()
        )
      },
      closeNotificationModesDialog: function () {
        this.closeSendNotifications()
        this.dialogNotificationModes = false
      },
      getClientNotes: function (client) {
        let data = {}
        try {
          if (client.notes) {
            data = JSON.parse(client.notes)
          }
        } catch { }
        return data
      },
      openClientWhatsapp: function (item) {
        if (item.mobile_phone) {
          const url = 'https://wa.me/' + item.mobile_phone
          window.open(url, '_blank')
        } else {
          this.$alert(
            window.strings['missing_whatsapp_mobile_number'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      },
      openClientWhatsappApi(item) {
        const self = this
        this.$isLoading(true)
        Api.getUserWithId({
          id: item.id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const client = Utils.encodeClient(response.data[0])
            self.showWhatsappApi.client = client
            if (self.showWhatsappApi.urls.length > 1) {
              self.dialogSelectClientWhatsappApi = true
            } else {
              self.selectClientWhatsappApi(self.showWhatsappApi.urls[0])
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      selectClientWhatsappApi(item) {
        this.dialogSelectClientWhatsappApi = false
        this.showWhatsappApi.url = item.url
        this.showWhatsappApi.notesKey = item.notes_key
        this.showWhatsappApi.messages = item.messages
        const notes = this.getClientNotes(this.showWhatsappApi.client)
        if (notes && notes[this.showWhatsappApi.notesKey]) {
          this.dialogClientWhatsappApi = true
        } else {
          this.openClientWhatsappApiMessage()
        }
      },
      closeClientWhatsappApi() {
        this.dialogClientWhatsappApi = false
      },
      openClientWhatsappApiMessage() {
        const self = this
        const fields = []
        this.showWhatsappApi.fields.forEach(function (f) {
          if (f.id === 'message') {
            fields.push({
              type: 'select',
              id: f.id,
              title: window.strings[f.id] ? window.strings[f.id] : f.id,
              key: f.label,
              items: Utils.getWhatsappMessages(self.showWhatsappApi.messages),
              value: '',
            })
          } else {
            fields.push({
              type: 'input',
              id: f.id,
              title: window.strings[f.id] ? window.strings[f.id] : f.id,
              key: f.label,
              value: self.showWhatsappApi.client[f.id],
              disabled: true,
            })
          }
        })
        this.clientWhatsappApiFields = fields
        this.dialogClientWhatsappApiMessage = true
        this.closeClientWhatsappApi()
      },
      closeClientWhatsappApiMessage() {
        this.dialogClientWhatsappApiMessage = false
        this.clientWhatsappApiFields = []
      },
      confirmClientWhatsappApiMessage() {
        const self = this
        const data = {
          url: this.showWhatsappApi.url,
          json: {}
        }
        for (let i = 0; i < this.clientWhatsappApiFields.length; i++) {
          if (!this.clientWhatsappApiFields[i].value) {
            return this.$alert(
              window.strings['please_insert_message'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
          if (this.clientWhatsappApiFields[i].id === 'mobile_phone') {
            let mobilePhoneValue = this.clientWhatsappApiFields[i].value
            if (this.clientWhatsappApiFields[i].value.indexOf('00') === 0) {
              mobilePhoneValue = mobilePhoneValue.slice(2)
            }
            if (this.clientWhatsappApiFields[i].value.indexOf('+') === 0) {
              mobilePhoneValue = mobilePhoneValue.slice(1)
            }
            data.json[this.clientWhatsappApiFields[i].key] = mobilePhoneValue
          } else {
            data.json[this.clientWhatsappApiFields[i].key] = this.clientWhatsappApiFields[i].value
          }
        }
        this.$isLoading(true)
        Api.postRequest(data, function (response) {
          if (response.success) {
            self.saveWhatsappLink(response.data)
            self.closeClientWhatsappApiMessage()
            self.$alert(
              window.strings['message_sent'],
              '',
              'success',
              Utils.getAlertOptions()
            )
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      saveWhatsappLink(data) {
        const self = this
        if (data && data !== 'Accepted') {
          const notes = this.getClientNotes(this.showWhatsappApi.client)
          notes[this.showWhatsappApi.notesKey] = data
          Api.updateUser({
            id: this.showWhatsappApi.client.id,
            notes: JSON.stringify(notes),
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.resetClientsList(true)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          this.$isLoading(false)
        }
      },
      openClientWhatsappApiLink() {
        const notes = this.getClientNotes(this.showWhatsappApi.client)
        this.closeClientWhatsappApi()
        window.open(notes[this.showWhatsappApi.notesKey], '_blank')
      },
      subscribeMqttTopic() {
        if (this.user && this.user.configurations && this.user.configurations.mqtt && this.user.configurations.mqtt.clients) {
          if (window.mqttDashboard) {
            window.mqttDashboard.subscribe(axios.defaults.dev ? 'dev/dashboard/' + axios.defaults.ptId + '/alerts' : 'dashboard/' + axios.defaults.ptId + '/alerts')
          }
          window.addEventListener('message', this.receiveMqttData)
        }
      },
      unsubscribeMqttTopic() {
        if (this.user && this.user.configurations && this.user.configurations.mqtt && this.user.configurations.mqtt.clients) {
          if (window.mqttDashboard) {
            window.mqttDashboard.unsubscribe(axios.defaults.dev ? 'dev/dashboard/' + axios.defaults.ptId + '/alerts' : 'dashboard/' + axios.defaults.ptId + '/alerts')
          }
          window.removeEventListener('message', this.receiveMqttData)
        }
      },
      receiveMqttData(event) {
        const self = this
        try {
          if (event && event.data && event.data.indexOf('setImmediate') !== 0) {
            const data = JSON.parse(event.data)
            if (data && data.event === 'dashboard-notification' && data.data) {
              const action = data.data
              if (action && action.id) {
                if (action.id === 'feedbacks') {
                  const filter = this.filters.find(function (item) {
                    return item.id === self.statusFilter
                  })
                  if (filter && filter.mqttRefresh) {
                    this.resetClientsListActiveFilter()
                  }
                  return true
                }
              }
            }
          }
        } catch { }
      },
      openBlockClients() {
        this.blockClientsList = []
        this.dialogBlockClients = true
      },
      closeBlockClients() {
        this.dialogBlockClients = false
        this.blockClientsList = []
      },
      confirmBlockClients() {
        const self = this
        this.$isLoading(true)
        Api.updateUsers({
          ids: this.blockClientsList,
          data: {
            status: self.statusMap.blocked
          },
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.resetClientsList()
            self.closeBlockClients()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
    },
    beforeDestroy: function () {
      this.unsubscribeMqttTopic()
    },
  }
</script>
